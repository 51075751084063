export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_PENDING_SUCCESS = "LOGIN_PENDING_SUCCESS";
export const LOGIN_PENDING_ERROR = "LOGIN_PENDING_ERROR";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const ENABLE_USER_ERROR = "ENABLE_USER_ERROR";
export const ENABLE_USER_PENDING = "ENABLE_USER_PENDING";
export const ENABLE_USER_PENDING_SUCCESS = "ENABLE_USER_PENDING_SUCCESS";
export const CLEAR_ENABLE_USER_ERROR = "CLEAR_ENABLE_USER_ERROR";
export const ENABLE_USER_PENDING_ERROR = "ENABLE_USER_PENDING_ERROR";
