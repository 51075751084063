import { SnackbarState} from "../../types/snackbar";
import { SNACKBAR_MESSAGE } from "../constants/snackbar.constants";

const initialState: SnackbarState = {
  message: "",
  isActive: false
};

export default (
  localState: SnackbarState = initialState,
  action: any
): SnackbarState => {
  switch (action.type) {
    case SNACKBAR_MESSAGE:
      if (action.payload)
        return {
          message: action.payload,
          isActive: true
        };
      return {
        ...localState,
        isActive: false
      };
    default:
      return localState;
  }
};
